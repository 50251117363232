import React, { useState, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"
import { Button, Dropdown } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import { useNavigate } from "react-router-dom"
import { MerchantContext } from "src/context/Merchant"
import { RouteType } from "src/constants/routeTypes"
import { useInitiateWebLinkRefund } from "src/api/user"
import env, { ENV } from "src/env"
import BlockUI from "src/helpers/BlockUI"
import { useGetExchangeRate } from "src/api/onRamp"
import { REFUND_AMOUNT_LIMIT } from "src/constants/Defaults"

import CustomerVeriRefundPopupModal from "./CustomerVeriRefundPopupModal"

function InitiateRefundPopupModal(props: any) {
  const { merchantDetails } = useContext(MerchantContext)
  const { txt, id, color, disabled, rowtrx, apicallstatus } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const [showCustomerForm, setShowCustomerForm] = useState(false)
  const [loaderShow, setLoaderShow] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState("")
  const [refundAmountLimit, setRefundAmountLimit] =
    useState(REFUND_AMOUNT_LIMIT)
  const [initiateData, setInitiateData] = useState({
    refundUserMobile: "",
    refundUserMobileCountryCode: "",
    refundUserEmail: "",
    refundAmountFiat: rowtrx.totalFiatReceived,
    refundMode: "CASH"
  })
  const [serverErrorMessage, setServerErrorMessage] = useState<string>()

  const {
    mutate: submitInitiateRefundTransaction,
    error,
    isSuccess
  } = useInitiateWebLinkRefund()
  const digitalAsset =
    env.TYPE == ENV.DEV || env.TYPE == ENV.TESTING ? "BTC" : "USDT"
  const { data: exchangeRatesData, isFetching: isFetchingExchangeRates } =
    useGetExchangeRate(rowtrx.fiatAsset)

  /*  useEffect(() => {
    if (merchantDetails?.merchant) {
      setRefundAmountLimit(merchantDetails.merchant.defaultRefundableFiatValue)
    }
  }, [merchantDetails]) */

  useEffect(() => {
    if (isSuccess) {
      setLoaderShow(true)
      setTimeout(() => {
        setShow(false)
        apicallstatus()
      }, 2000)
    }
  }, [isSuccess])

  useEffect(() => {
    if (error?.message) {
      setLoaderShow(false)
      setServerErrorMessage(error?.message)
    }
  }, [error])

  useEffect(() => {
    if (rowtrx) {
      if (
        rowtrx.totalFiatReceived &&
        rowtrx.totalFiatReceived > refundAmountLimit
      ) {
        setInitiateData({ ...initiateData, refundMode: "WALLET" })
      }
    }
  }, [rowtrx])

  const handleClose = () => {
    setInitiateData({
      ...initiateData,
      refundAmountFiat: rowtrx.totalFiatReceived,
      refundUserEmail: "",
      refundUserMobile: ""
    })
    setShow(false)
    setServerErrorMessage("")
  }
  const handleShow = () => setShow(true)

  function renderRefundAmount() {
    if (exchangeRatesData && initiateData) {
      const value =
        exchangeRatesData[digitalAsset] * initiateData.refundAmountFiat
      const finalVal = value.toFixed(8)
      return parseFloat(finalVal)
    }
  }

  function submitData() {
    // console.log(id)
    if (initiateData.refundAmountFiat > refundAmountLimit) {
      if (
        initiateData.refundUserMobile === "" &&
        initiateData.refundUserEmail === ""
      ) {
        setFormErrorMessage(
          "Fill Mobile Number or Email Address or Enter Customers Info Manually"
        )
        setTimeout(() => {
          setFormErrorMessage("")
        }, 2000)
        return
      }
    }
    if (
      initiateData.refundUserMobile !== "" &&
      initiateData.refundUserMobileCountryCode === ""
    ) {
      setFormErrorMessage("Select Mobile Code")
      setTimeout(() => {
        setFormErrorMessage("")
      }, 2000)
      return
    }
    if (initiateData.refundAmountFiat == 0) {
      setFormErrorMessage("Refund amount should be greater than 0")
      setTimeout(() => {
        setFormErrorMessage("")
      }, 2000)
      return
    }
    // const obj = {
    //   refundUserMobile:
    //     initiateData.refundUserMobileCountryCode +
    //       initiateData.refundUserMobile ===
    //     ""
    //       ? null
    //       : initiateData.refundUserMobileCountryCode +
    //         initiateData.refundUserMobile,
    //   refundUserEmail:
    //     initiateData.refundUserEmail === ""
    //       ? null
    //       : initiateData.refundUserEmail,
    //   refundDigitalType: rowtrx.asset,
    //   refundFiatType: rowtrx.fiatAsset,
    //   refundAmountFiat: initiateData.refundAmountFiat,
    //   transactionId: rowtrx.uuid,
    //   refundMode: initiateData.refundMode,
    //   refundCustomerFormUrl:
    //     window.location.protocol + "//" + window.location.hostname + "/",
    //   refundAmountDigital: renderRefundAmount() || 0
    // }
    // console.log(obj)
    if (rowtrx.totalFiatReceived <= refundAmountLimit) {
      setLoaderShow(true)
      submitInitiateRefundTransaction({
        refundDigitalType: rowtrx.asset,
        refundFiatType: rowtrx.fiatAsset,
        refundAmountFiat: initiateData.refundAmountFiat,
        transactionId: rowtrx.uuid,
        refundMode: initiateData.refundMode,
        refundAmountDigital: renderRefundAmount() || 0,
        refundCustomerFormUrl:
          window.location.protocol + "//" + window.location.hostname + "/"
      })
    } else {
      setLoaderShow(true)
      submitInitiateRefundTransaction({
        refundUserMobile:
          initiateData.refundUserMobileCountryCode +
            initiateData.refundUserMobile ===
          ""
            ? null
            : initiateData.refundUserMobileCountryCode +
              initiateData.refundUserMobile,
        refundUserEmail:
          initiateData.refundUserEmail === ""
            ? null
            : initiateData.refundUserEmail,
        refundDigitalType: rowtrx.asset,
        refundFiatType: rowtrx.fiatAsset,
        refundAmountFiat: initiateData.refundAmountFiat,
        transactionId: rowtrx.uuid,
        refundMode:
          initiateData.refundAmountFiat <= refundAmountLimit
            ? "CASH"
            : "WALLET",
        refundCustomerFormUrl:
          window.location.protocol + "//" + window.location.hostname + "/",
        refundAmountDigital: renderRefundAmount() || 0
      })
    }
  }

  function storeData(e: any, input: any) {
    switch (input) {
      case "refundAmount":
        if (!isNaN(e.target.value)) {
          setInitiateData({ ...initiateData, refundAmountFiat: e.target.value })
        }
        break
      case "mobileCode":
        setInitiateData({
          ...initiateData,
          refundUserMobileCountryCode: e.target.value
        })
        break
      case "mobileNumber":
        setInitiateData({ ...initiateData, refundUserMobile: e.target.value })
        break
      case "email":
        setInitiateData({ ...initiateData, refundUserEmail: e.target.value })
        break
      default:
        break
    }
  }

  return (
    <>
      <button
        className={`${color} ${
          disabled &&
          (rowtrx.refundApprovalComment == null
            ? rowtrx.refundAcceptanceComment
            : rowtrx.refundApprovalComment)
            ? "tooltip"
            : null
        }`}
        onClick={handleShow}
        disabled={disabled}
        style={{ pointerEvents: "auto" }}
        data-title={
          disabled &&
          (rowtrx.refundApprovalComment == null
            ? rowtrx.refundAcceptanceComment
            : rowtrx.refundApprovalComment)
        }
      >
        {t(txt)}
      </button>

      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <BlockUI blocking={loaderShow} title="submitting" />
        <Modal.Header closeButton>
          <Modal.Title>Send Web form to customer for Refund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {serverErrorMessage && (
            <div className="alert alert-danger" role="alert">
              {serverErrorMessage}
            </div>
          )}
          {initiateData.refundAmountFiat > rowtrx.totalFiatReceived && (
            <div className="alert alert-danger" role="alert">
              Refund Amount should not be more than received amount
            </div>
          )}
          <Form>
            <Form.Group className="mb-3">
              {formErrorMessage && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{ textAlign: "center" }}
                >
                  {formErrorMessage}
                </div>
              )}
              {/* <Form.Label>Refund Amount in {rowtrx.fiatAsset}</Form.Label>
              <Form.Control
                type="text"
                placeholder={`Refund Amount in ${rowtrx.fiatAsset}`}
                value={initiateData.refundAmountFiat}
                onChange={(e) => storeData(e, "refundAmount")}
              /> */}
              <Form.Label>
                Maximum Refund Eligibility in{" "}
                {rowtrx.fiatAsset && rowtrx.fiatAsset}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={`Received Fiat Amount in ${
                  rowtrx?.fiatAsset && rowtrx?.fiatAsset
                }`}
                value={rowtrx?.totalFiatReceived}
                onChange={(e) => storeData(e, "refundAmount")}
                disabled={true}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Refund Amount in {rowtrx.fiatAsset && rowtrx.fiatAsset}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={`Refund Amount in ${
                  rowtrx?.fiatAsset && rowtrx?.fiatAsset
                }`}
                value={initiateData.refundAmountFiat}
                onChange={(e) => storeData(e, "refundAmount")}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Refund Mode</Form.Label>
              <Form.Control
                type="text"
                placeholder="Refund Mode"
                value={
                  initiateData.refundAmountFiat <= refundAmountLimit
                    ? "cash"
                    : "wallet"
                }
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Mobile number</Form.Label>
              <div className="mobileNumberInputs">
                <Form.Select
                  disabled={
                    initiateData.refundAmountFiat <= refundAmountLimit ||
                    initiateData.refundUserEmail.length > 0
                  }
                  onChange={(e) => storeData(e, "mobileCode")}
                >
                  <option value="">Code</option>
                  <option value="+91">+91</option>
                  <option value="+971">+971</option>
                  <option value="+61">+61</option>
                </Form.Select>
                <Form.Control
                  type="text"
                  placeholder="Mobile number"
                  disabled={
                    initiateData.refundAmountFiat <= refundAmountLimit ||
                    initiateData.refundUserEmail.length > 0
                  }
                  onChange={(e) => storeData(e, "mobileNumber")}
                />
              </div>
            </Form.Group>
            <div>OR</div>
            <Form.Group className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email Address"
                disabled={
                  initiateData.refundAmountFiat <= refundAmountLimit ||
                  initiateData.refundUserMobile.length > 0 ||
                  initiateData.refundUserMobileCountryCode.length > 0
                }
                onChange={(e) => storeData(e, "email")}
              />
            </Form.Group>
            <div>OR</div>
            <Form.Group
              className="mb-3"
              style={{ justifyContent: "space-between", display: "flex" }}
            >
              <div>
                <button
                  type="button"
                  className="btn wdz-btn-primary"
                  disabled={
                    initiateData.refundAmountFiat > rowtrx.totalFiatReceived ||
                    initiateData.refundAmountFiat <= refundAmountLimit ||
                    initiateData.refundUserMobile.length > 0 ||
                    initiateData.refundUserEmail.length > 0 ||
                    initiateData.refundUserMobileCountryCode.length > 0
                  }
                  onClick={() => setShowCustomerForm(!showCustomerForm)}
                >
                  Customers Info Manually
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                  style={{ marginRight: "10px" }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={
                    initiateData.refundAmountFiat > rowtrx.totalFiatReceived
                  }
                  onClick={() => submitData()}
                >
                  Submit
                </button>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
      {showCustomerForm ? (
        <CustomerVeriRefundPopupModal
          rowtrx={props.rowtrx}
          showhide={() => setShowCustomerForm(false)}
          refundableFiatAmount={initiateData.refundAmountFiat}
        />
      ) : null}
    </>
  )
}

export default InitiateRefundPopupModal
