const REFUND_AMOUNT_LIMIT = 5
const WADZPAY_LOGO = true
const DISPLAY_ATM_MENU = false
const ATM_BANK_NAME = "ATM"
const WALLET_MAX_LENGTH = 48 // FOR DDF 42
const WALLET_MIN_LENGTH = 32 // FOR DDF 42
const WALLET_REGEX = /^[0-9a-zA-Z]*$/ // FOR DDF /^0x[0-9a-fA-F]{40}$/
const DIGITAL_ASSET = "USDT"

export {
  REFUND_AMOUNT_LIMIT,
  WADZPAY_LOGO,
  DISPLAY_ATM_MENU,
  ATM_BANK_NAME,
  WALLET_MAX_LENGTH,
  WALLET_MIN_LENGTH,
  WALLET_REGEX,
  DIGITAL_ASSET
}
