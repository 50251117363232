/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
import { useState } from "react"

import {
  signInAsync,
  requestResetPasswordCodeAsync,
  submitResetPasswordCodeAsync
} from "../auth/AuthManager"
import {
  Transaction,
  TransactionsData,
  RefundTransactionsData,
  BalancesData,
  MerchantData,
  MerchantDetailsData,
  UserDetailsData,
  VerifyEmailOTPData,
  P2pTransaction,
  AcceptRejectRefundableTransaction,
  InitiateWebLinkRefund,
  SubmitRefundFormData,
  GetRefundFormToken,
  SubmitRefundWithAuth,
  RecentTransaction,
  WalletWithdraw,
  UserData
} from "./models"
import { EndpointKey, useApi } from "./constants"

import { useGet, useSet } from "./index"

const useApiCallStates = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const reset = () => {
    setIsSuccess(false)
    setIsLoading(false)
    setError(null)
  }
  return {
    isLoading,
    setIsLoading,
    isSuccess,
    setIsSuccess,
    error,
    setError,
    reset
  }
}

export const useGenericApiCall = (
  apiToCall: (...params: any) => Promise<any>
) => {
  const {
    isLoading,
    setIsLoading,
    isSuccess,
    setIsSuccess,
    error,
    setError,
    reset
  } = useApiCallStates()

  return {
    mutate: async (...params: any) => {
      try {
        setIsLoading(true)
        const response = await apiToCall(...params)
        setIsSuccess(true)
        return response
      } catch (e: any | null) {
        setError(e)
      } finally {
        setIsLoading(false)
      }
    },
    isLoading,
    isSuccess,
    error,
    reset
  }
}

export const useSignIn = () => {
  return useGenericApiCall(signInAsync)
}

export const useUserDetailsAndEmailOTP = () =>
  useSet<UserDetailsData>(
    EndpointKey.USER_DETAILS_SEND_EMAIL_OTP,
    useApi().userDetailsAndEmailOTP(),
    "POST",
    {},
    EndpointKey.USER_DETAILS_SEND_EMAIL_OTP
  )

export const useMerchant = () =>
  useSet<MerchantData>(
    EndpointKey.MERCHANT,
    useApi().merchant(),
    "POST",
    {},
    EndpointKey.USER_DETAILS_SEND_EMAIL_OTP
  )

export const useVerifyEmailOTPAndCreateUser = () =>
  useSet<VerifyEmailOTPData>(
    EndpointKey.VERIFY_EMAIL_OTP_CREATE_USER,
    useApi().verifyEmailOTPAndCreateUser(),
    "POST",
    {},
    EndpointKey.VERIFY_EMAIL_OTP_CREATE_USER
  )

export const useRequestResetPasswordCode = () => {
  return useGenericApiCall(requestResetPasswordCodeAsync)
}

export const useSubmitResetPasswordCode = () => {
  return useGenericApiCall(submitResetPasswordCodeAsync)
}

export const useMerchantTransaction = (id: string) =>
  useGet<Transaction>(
    [EndpointKey.MERCHANT_TRANSACTION, id],
    useApi().merchantTransaction(id),
    { keepPreviousData: true }
  )

export const useMerchantTransactions = (query?: string) =>
  useGet<TransactionsData>(
    [EndpointKey.MERCHANT_TRANSACTIONS, query],
    useApi().merchantTransactions(query || ""),
    { keepPreviousData: true },
    "uuid"
  )
export const useMerchantTransactionsNoPage = (query?: string) =>
  useGet<TransactionsData>(
    [EndpointKey.MERCHANT_TRANSACTIONS, query],
    useApi().merchantTransactions(query || ""),
    { keepPreviousData: true },
    "uuid"
  )

export const useRefundTransactions = (query?: string) =>
  useGet<RefundTransactionsData>(
    [EndpointKey.REFUND_TRANSACTIONS, query],
    useApi().refundTransactions(query || ""),
    { keepPreviousData: true },
    "uuid"
  )
export const useRefundTransactionsNoPage = (query?: string) =>
  useGet<RefundTransactionsData>(
    [EndpointKey.REFUND_TRANSACTIONS, query],
    useApi().refundTransactions(query || ""),
    { keepPreviousData: true },
    "uuid"
  )

export const useRefundAcceptRefundTrx = () =>
  useSet<AcceptRejectRefundableTransaction>(
    EndpointKey.REFUND_ACCEPT_REJECT_TRANSACTIONS,
    useApi().refundAcceptRejectTransactions(),
    "POST",
    {},
    EndpointKey.REFUND_ACCEPT_REJECT_TRANSACTIONS
  )

export const useInitiateWebLinkRefund = () =>
  useSet<InitiateWebLinkRefund>(
    EndpointKey.REFUND_INITIATE_WEBLINK,
    useApi().refundInitiateWebLink(),
    "POST",
    {},
    EndpointKey.REFUND_INITIATE_WEBLINK
  )

export const useSubmitRefundformWithAuth = () =>
  useSet<SubmitRefundWithAuth>(
    EndpointKey.REFUND_SUBMIT_WITHAUTH,
    useApi().refundSubmitFormWithAuth(),
    "POST",
    {},
    EndpointKey.REFUND_SUBMIT_WITHAUTH
  )

export const useUserBalances = () =>
  useGet<BalancesData>(EndpointKey.USER_BALANCES, useApi().userBalances(), {})

export const useP2pTransaction = () =>
  useSet<P2pTransaction>(
    EndpointKey.P2P_TRANSACTIONS,
    useApi().p2pTransaction(),
    "POST",
    {},
    EndpointKey.P2P_TRANSACTIONS
  )

export const useMerchantDetails = () =>
  useGet<MerchantDetailsData>(
    EndpointKey.MERCHANT_DETAILS,
    useApi().merchantDetails(),
    {}
  )

export const useSubmitRefundFormData = () =>
  useSet<SubmitRefundFormData>(
    EndpointKey.SUBMIT_REFUND_FORM_DATA,
    useApi().submitRefundForm(),
    "POST",
    {},
    EndpointKey.SUBMIT_REFUND_FORM_DATA
  )

export const useRefundFormTokenDetails = () =>
  useSet<GetRefundFormToken>(
    EndpointKey.GET_REFUND_FORM,
    useApi().getRefundForm(),
    "POST",
    {},
    EndpointKey.GET_REFUND_FORM
  )

export const useRecentPayment = () =>
  useSet<RecentTransaction>(
    EndpointKey.RECENT_PAYMENT,
    useApi().recentPayment(),
    "POST",
    {},
    EndpointKey.RECENT_PAYMENT
  )

export const useWalletWithdraw = () =>
  useSet<WalletWithdraw>(
    EndpointKey.ATM_WITHDRAW,
    useApi().atmWithDraw(),
    "POST",
    {},
    EndpointKey.ATM_WITHDRAW
  )

export const useUserVerify = (query: string) =>
  useGet<UserData>(
    [EndpointKey.USER_VERIFY, query],
    useApi().userVerify(query),
    { enabled: query != "" }
  )
